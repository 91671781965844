<template>
  <div id="app">
	<common-header :title="$route.meta.title"></common-header>
    <router-view v-if="isAuth"/>
	<loading v-if="LOADING" />
  </div>
</template>
<script>
import { isMobile } from './utils';
import { MessageBox, Message, Notification } from "element-ui";
import { initColor } from '@/utils/common.js'
import { auth,sysConfig,tgAuth} from '@/api/service.js'
import Cookies from 'js-cookie'
import commonHeader from '@/components/common/commonHeader.vue'
import loading from "@/components/Loading"
import { mapState } from "vuex";


export default {
  components:{
	commonHeader,
	loading
  },	
  data() {
    return {
	  isMobileFlag:null,
  	  isAuth:false,
  	  routeInfo:{},
  	  urlParam:null,
	  currentPage:'',
	  showRouter:false
    }
  },
  computed:{
	...mapState(["LOADING"]),  
  },
  mounted(){
     this.isMobileFlag = isMobile()
     document.body.classList.add(this.isMobileFlag?'mobile':'pc') 
  },
  created() {
	this.urlParam = this.$store.state.urlParam
	initColor('#46C4CE',null);
  	//this.getRouteInfo()
	this.init()
	//this.getAccessToken()
  },
  methods:{
	  tip(msg){
	  	Message({
	  	    showClose: true,
	  	    message: msg,
	  	    type: 'error'
	  	    });
	  },
	  
	  init(){
		console.log(window.Telegram)
		
		if(!window.Telegram || JSON.stringify(window.Telegram.WebApp.initDataUnsafe)==='{}'){
			this.getRouteInfo()
		}else{
			this.$store.commit("showLoading");
			let userData  = window.Telegram.WebApp.initDataUnsafe.user
			let firstName = userData.first_name
			let lastName = userData.last_name
			let userName = firstName+' '+lastName
			let userId = userData.id
			tgAuth({
				userName:userName,
				userId:userId
			}).then(res=>{
				if(res.code === '0'){
					Cookies.set('token', res.data.accessToken)			
					this.getSysConfig()
					//this.isAuth = true
				}
			})
		}
		
	  },
	  getRouteInfo(){
		  //debugger
		  //console.log(this.GetRequest())
		 this.$store.commit("showLoading");
	  	this.$route.query
	  	  ? (this.routeInfo = JSON.parse(JSON.stringify(this.GetRequest())))
	  	  : (this.routeInfo = {});
		
	  	if(this.routeInfo.tranType){
	  		this.routeInfo.tranType =  this.routeInfo.tranType.toLowerCase()
	  	}  	
		
	  	let urlParamKeys = Object.keys(this.urlParam)
	  	for(let i=0;i<urlParamKeys.length;i++){
	  		if(this.urlParam[urlParamKeys[i]].required && !this.routeInfo[urlParamKeys[i]]){
	  			this.tip(urlParamKeys[i]+" required")
	  			return 
	  		}else{
	  			this.$store.commit('updateUrlParam',{
	  				key:urlParamKeys[i],
	  				value:this.routeInfo[urlParamKeys[i]]
	  			}
	  			)
	  		}
	  		
	  	}
	  	this.currentPage=this.routeInfo.tranType?this.routeInfo.tranType:'convert'

	  
	  	this.getAccessToken()
	  
	  },
	  getAccessToken(){
	  	let param = {}
	  	 // param.apiKey = 'czZPY0Zpd1g'
	  	 // param.partnerUserId = 'test123'
	  	 // param.timestamp = 1686376969773
	  	 // param.signature = '3cc2f8973db1bf3c03bb260e5a43f8e305d6457b619f3a308caf549278a4f746'
	  	param.apiKey = this.routeInfo.apiKey
	  	param.partnerUserId = this.routeInfo.partnerUserId
	  	param.timestamp = this.routeInfo.timestamp
	  	param.signature = this.routeInfo.signature
		param.email =  this.routeInfo.email
		//param.email =  '123@gmail.com'
	  	auth(param).then(res=>{
	  		if(res.code === '0'){
	  			Cookies.set('token', res.data.accessToken)			
	  			this.getSysConfig()
				//this.isAuth = true
	  		}
	  	})
	  },
	  getSysConfig(){
	  	sysConfig().then(res => {
	  		if(res.code ==='0'){
	  			initColor(res.data.merchantConfig.themeColor,res.data.merchantConfig.bgColor);
	  			//initColor('#ff3300','#ff3300');
				this.$store.commit('SET_SYSCONFIG',res.data)
	  		}
	  		this.isAuth = true
	  		this.$store.commit("hideLoading");
	  	})
	  },
	  // 分割url
	   GetRequest() {
	      var fullURL = window.location.href;
	      var theRequest = new Object();
	      let strs;
	      if (fullURL.indexOf("?") != -1) {
	          var str = fullURL.substr(fullURL.indexOf("?")+1);
	          strs = str.split("&");
	          for (var i = 0; i < strs.length; i++) {
	              theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
	          }
	      }
	      return theRequest;
	  }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
