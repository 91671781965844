import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/giftCardList'
  },
  
  {
    path:'/giftDetail',
    name:'giftDetail',
    meta:{
      title:'giftDetail'
    },
    component:()=> import('@/components/gift-detail.js')
  },
  {
    path:'/orderPay',
    name:'orderPay',
    meta:{
      title:'orderPay'
    },
    component:()=> import('@/components/order-pay.js')
  },
  {
    path:'/historicalOrders',
    name:'historicalOrders',
    meta:{
      title:'historicalOrders'
    },
    component:()=> import('@/components/historical-orders.js')
  },
  
  {
    path:'/giftCardList',
    name:'giftCardList',
    meta:{
      title:'giftCardList'
    },
    component:()=> import('@/components/giftCardList.js')
  },
  
  // 礼品卡移动端页面
  // {
  //   path:'/index',
  //   name:'index',
  //   meta:{
  //     title:''
  //   },
  //   component:()=> import('@/components/mobile/index.vue')
  // },
 
 
  // {
  //   path:'/query',
  //   name:'query',
  //   meta:{
  //     title:''
  //   },
  //   component:()=> import('@/components/mobile/query.vue')
  // },
  {
    path:'*',
    component:()=>import('@/components/common/404.vue')
  }
]

const router = new VueRouter({
  
  routes
})

router.beforeEach((to,from,next)=>{
	let query = from.query
	//debugger
  if(to.name == 'static' || location.href.includes('static')){
    next()
  }else{
	  // next()
    if(!to.query.apiKey && from.name != to.name){
	  if(to.query){
		  query = Object.assign({},query,to.query)
	  }
      const p = Object.assign({},to,{query})
      const href = router.resolve(p).href
	  
	  if(JSON.stringify(window.Telegram.WebApp.initDataUnsafe)!=='{}'){
	  	next()  
	  }else{
		  location.href = href
	  }
      
    }else{
      next()  
    }
  }
 
})
export default router
